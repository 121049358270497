.absolutely-page {
  .contentSpacing {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .coconutCutImg {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
  }

  .shopSection {
    padding-top: 100px;
    padding-bottom: 10em;
    .container {
      width: 62%;
    }
    .btn.btn-large {
      padding: 10px 20px;
      font-size: 20px;
      min-height: 56px;
    }
  }

  .contactSection {
    // padding-top: 12em;
    .container {
      max-width: 800px;
    }

    .contactBg {
      background-color: var(--sub-bg-color);
    }
  }

  .beachSection {
    // padding-top: 10em;
    .beachImg {
      // width: 100%;
      height: 600px;
      // object-fit: cover;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top;
    }
  }
}

@media screen and (max-width: 768px) {
  // Product Item page
  .product-page {
    .productBrief {
      display: block;
    }

    .ftImgWrapper {
      max-width: 70%;
      margin: 0 auto;

      img {
        width: 100%;
      }
    }

    .productFeature {
      .productFtImgSec {
        margin-bottom: 150px;

        .gridContent {
          grid-template-columns: 1fr;
          gap: 20px;
        }

        img {
          width: 100%;
        }
      }
    }
  }
}
