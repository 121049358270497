.homePage {
  .productHero {
    text-align: center;
    margin: 50px 0;

    img {
      width: 100%;
      max-width: 800px;
    }

    .productHeroDesc {
      font-size: 42px;
      font-weight: bold;
      max-width: 700px;
      margin: 0 auto;
      text-transform: uppercase;
    }
  }

  .productIngredientRow {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
    row-gap: 50px;
    margin-bottom: 50px;

    img {
      width: 262px;
      height: 262px;
      object-fit: contain;
      margin-bottom: 20px;
      // border-radius: 50%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    .desc {
      font-size: 16px;
      text-align: center;
      line-height: normal;
    }
  }

  .productIngredientDesc {
    margin-bottom: 100px;
  }

  .mctBenefits {
    margin-bottom: 50px;
  }

  .gutHealth {
    margin: 50px 0;
  }

  // NEW
  .essentialRecommend {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;

    .recommendImg {
      max-width: 500px;
      // height: 500px;
    }
  }

  .sachetTitle {
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 35px;
  }

  .ingredientSwiper .swiper-pagination-bullet-active {
    background: var(--primary-color) !important;
  }

  .rethinkContainer {
    max-width: 800px;
    margin: 0 auto;

    .rethinkSection {
      display: flex;
      flex-direction: column;
      gap: 40px;

      .rethinkCol {
        display: flex;
        gap: 40px;
        align-items: center;
      }
      .rethinkCol.specialDirection {
        flex-direction: row-reverse;
      }

      .rethinkText {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      .rethinkImg {
        width: 100%;
        max-width: 300px;
        object-fit: cover;
        border-radius: 500px;
        object-position: center;
      }
    }
  }

  .happyUsersSwiper {
    padding: 0 80px;
    position: relative;

    .happyUserSwipeItem {
      display: flex;
      gap: 30px;
      align-items: center;
      width: 100%;
    }

    .swiper-custom-nav {
      background: transparent;
      width: 40px;
      height: 40px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .swiper-custom-prev {
      left: 0;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
      border-right: 20px solid #777;
    }

    .swiper-custom-next {
      right: 0;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
      border-left: 20px solid #777;
    }
  }

  .moreGreatPplGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }

  .combo-container {
    max-width: 800px;
    width: 90%;
    margin: 0 auto;

    .comboPackageGrid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;

      .comboPackageGridItem {
        border: 1px solid #e5e7eb;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 25px;
      }
    }
  }

  .betterLivingAccordion {
    .betterLivingAccordionItem {
      position: relative;
      background-color: black;
      color: white;
      padding: 20px;
      margin-bottom: 20px;

      .betterLivingItemTitle {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 20px;
        margin-bottom: 5px;
      }

      .betterLivingItemSubtitle {
        font-size: 18px;
      }
    }

    .betterLivingAccordionItem:last-child {
      margin-bottom: 0;
    }

    .plusIcon {
      position: absolute;
      top: 50% !important;
      transform: translateY(-50%) !important;
      right: 20px !important;
      color: white;
      font-size: 25px;
    }
    // .ant-collapse-expand-icon {
    //   position: absolute;
    //   top: 50% !important;
    //   transform: translateY(-50%) !important;
    //   right: 20px !important;
    // }
  }

  // Diff Table
  .diff-table {
    .diff-table-row {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr;
      align-items: center;
    }
  }

  // LEAN ESSENTIALS
  .leanListenExp {
    background-image: linear-gradient(
      to bottom,
      #292524 0%,
      #27272a 40%,
      #3f3f46 100%
    );

    .leanRecommend {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 50px;
    }

    .recommendImg {
      max-width: 500px;
    }
  }

  .coreBenefitsGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
  }

  .leanBetterLivingGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }

  .grayCoconutBg {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}

@media screen and (max-width: 1200px) {
  .homePage {
    .productIngredientRow {
      grid-template-columns: repeat(2, 1fr);
      margin-bottom: 30px;
    }
  }
}

@media screen and (max-width: 768px) {
  .homePage {
    .productHero {
      .productHeroDesc {
        font-size: 26px;
      }
    }

    .productIngredientRow {
      grid-template-columns: 1fr;
      margin-bottom: 30px;
    }

    .productIngredientDesc {
      ol,
      ul {
        padding-left: 10px;
      }
    }

    // NEW
    .essentialRecommend {
      flex-direction: column;
      padding-bottom: 20px;

      .recommendImg {
        width: 100%;
        // height: 300px;
      }
    }

    .sachetTitle {
      flex-direction: column;
      font-size: 30px;
    }

    .rethinkContainer {
      max-width: 800px;
      margin: 0 auto;

      .rethinkSection {
        display: flex;
        flex-direction: column;
        gap: 40px;

        .rethinkCol {
          flex-direction: column-reverse;
          align-items: center;
        }
        .rethinkCol.specialDirection {
          flex-direction: column-reverse;
        }

        .rethinkText {
          display: flex;
          flex-direction: column;
          gap: 20px;
        }

        .rethinkImg {
          width: 100%;
          max-width: 300px;
          object-fit: cover;
          border-radius: 500px;
          object-position: center;
        }
      }
    }

    .happyUsersSwiper {
      padding: 0 25px;
      position: relative;

      .happyUserSwipeItem {
        flex-direction: column;
        width: 100%;
        align-items: unset;
      }

      .swiper-custom-nav {
        width: 30px;
        height: 30px;
      }

      .swiper-custom-prev {
        left: -15px;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
        border-right: 15px solid #777;
      }

      .swiper-custom-next {
        right: -15px;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
        border-left: 15px solid #777;
      }
    }

    .betterLivingAccordion {
      .betterLivingAccordionItem {
        padding: 20px;

        .betterLivingItemTitle {
          font-size: 16px;
        }

        .betterLivingItemSubtitle {
          font-size: 12px;
        }
      }

      .betterLivingAccordionItem:last-child {
        margin-bottom: 0;
      }

      .plusIcon {
        right: 5px !important;
        font-size: 16px;
      }
    }

    .diff-table {
      overflow-x: auto;
      .diff-table-row {
        grid-template-columns: 300px 200px 150px;
      }
    }

    .moreGreatPplGrid {
      grid-template-columns: 1fr;
    }

    .combo-container {
      .comboPackageGrid {
        grid-template-columns: 1fr;
      }
    }

    // LEAN ESSENTIALS
    .leanListenExp {
      padding: 20px 0;
      .leanRecommend {
        flex-direction: column;

        .recommendImg {
          width: 100%;
          // height: 300px;
        }
      }
    }

    .coreBenefitsGrid {
      grid-template-columns: 1fr;
    }

    .leanBetterLivingGrid {
      grid-template-columns: 1fr;
    }
  }
}
