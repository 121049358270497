.whyPage {
  .contentSpacing {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .foundationalBenefits {
    padding-top: 10em;
  }

  .foundationalBenefits:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10em;
    background-color: #cf1c24;
    transform: skewY(5deg);
    transform-origin: top left;
    z-index: -1;
  }

  .foundationalList {
    .foundationalListItem {
      min-width: 500px;
      max-width: 500px;
      margin: auto;
      font-weight: bold;
      font-size: 30px;
    }
  }

  .benefitsInfo {
    padding-top: 10em;
    .container {
      width: 62%;
    }
  }

  .benefitsInfo:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10em;
    background-color: #cf1c24;
    transform: skewY(355deg);
    transform-origin: top left;
    z-index: -1;
  }

  .productSection {
    background-size: cover;
    background-position: top center;
    padding-top: 80px;
    padding-bottom: 50px;

    .productBoxes {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      max-width: 80%;
      margin: 0 auto;
      text-align: center;
      gap: 30px;

      .productBox {
        img {
          width: 100%;
          height: 350px;
          object-fit: contain;
          margin-bottom: 50px;
        }

        .productLabel {
          font-size: 22px;
        }
      }
    }
  }

  .shopSection {
    padding-top: 100px;
    padding-bottom: 10em;
    .container {
      width: 62%;
    }
    .btn.btn-large {
      padding: 10px 20px;
      font-size: 20px;
      min-height: 56px;
    }
  }

  .sloganSection {
    .sloganBg {
      background-color: var(--secondary-bg-color);
      padding-top: 50px;
      padding-bottom: 3em;
    }

    .stylishTitle {
      letter-spacing: 20px;
    }
    .container {
      max-width: 800px;
    }
  }

  .sloganSection:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10em;
    background-color: #000;
    transform: skewY(355deg);
    transform-origin: top left;
    z-index: -1;
  }

  .contactSection {
    padding-top: 12em;
    .container {
      max-width: 800px;
    }

    .contactBg {
      background-color: var(--sub-bg-color);
    }
  }

  .contactSection:before {
    content: "";
    position: absolute;
    top: -10em;
    left: 0;
    width: 100%;
    height: 10em;
    background-color: #000;
    transform: skewY(5deg);
    transform-origin: bottom left;
    z-index: -1;
  }

  .beachSection {
    padding-top: 10em;
    .beachImg {
      // width: 100%;
      height: 600px;
      // object-fit: cover;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .beachSection:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10em;
    background-color: #fff;
    transform: skewY(5deg);
    transform-origin: top left;
  }
}

@media screen and (max-width: 768px) {
  .whyPage {
    .foundationalList {
      .foundationalListItem {
        min-width: 300px;
        max-width: 300px;
        margin: auto;
        font-weight: bold;
        font-size: 20px;
      }
    }

    .productSection {
      .productBoxes {
        display: block;
        max-width: unset;

        .productBox {
          img {
            width: 100%;
            max-width: 300px;
            height: 250px;
            object-fit: contain;
            margin-bottom: 50px;
          }

          .productLabel {
            font-size: 22px;
          }
        }
      }
    }

    .beachSection {
      padding-top: 10em;
      .beachImg {
        // width: 100%;
        height: 300px;
        // object-fit: cover;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}
