// Skew UI
.skewFromRight {
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10em;
    transform: skewY(355deg);
    transform-origin: top left;
    z-index: -1;
  }

  &.bgBlack {
    &::before {
      background-color: #000;
    }
  }

  &.bgRed {
    &::before {
      background-color: var(--primary-bg-color);
    }
  }

  .blackSkewBg {
    background-color: var(--secondary-bg-color);
    padding-top: 50px;
    padding-bottom: 3em;
  }
  // .container {
  //   max-width: 800px;
  // }
  + .skewFromLeft {
    padding-top: 12em;
    padding-bottom: 12em;
  }
}

.skewFromLeft {
  // .container {
  //   max-width: 800px;
  // }
  &::before {
    content: "";
    position: absolute;
    top: -10em;
    left: 0;
    width: 100%;
    height: 10em;
    transform: skewY(5deg);
    transform-origin: bottom left;
    z-index: -1;
  }
  &.bgBlack {
    &::before {
      background-color: #000;
    }
  }
  &.bgRed {
    &::before {
      background-color: var(--primary-bg-color);
    }
  }

  .blackSkewBg {
    background-color: var(--secondary-bg-color);
    padding-top: 50px;
    padding-bottom: 3em;
  }

  .whiteSkewBg {
    background-color: var(--sub-bg-color);
  }

  // + .skewFromRight {
  //   padding-top: 12em;
  // }
}

.skewRow {
  img {
    display: block;
    max-width: 450px;
  }
}

@media screen and (max-width: 768px) {
  // Skew UI
  .skewFromRight {
    + .skewFromLeft {
      padding-top: 6em;
      padding-bottom: 12em;
    }
  }
}
