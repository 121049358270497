// ImgTxtTwoCol
.row {
  display: flex;
  align-items: center;
  gap: 20px;

  .colTxt {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .title {
      font-size: 35px;
      font-weight: bold;
    }

    .body {
      display: flex;
      flex-direction: column;
      gap: 20px;
      // width: 75%;
    }
  }

  .colImg {
    width: 50%;
    img {
      width: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  .row {
    flex-direction: column;

    .colTxt {
      width: 100%;
      .title {
        font-size: 26px;
      }

      .body {
        width: 100%;
      }
    }

    .colImg {
      width: 100%;
    }
  }
}
