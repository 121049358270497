.communityBlogPage {
  .communityBlogBlueSection {
    background-color: #bae6fd;
    padding-bottom: 5em;

    .sectionContainer {
      display: flex;
      gap: 50px;
      align-items: flex-start;
      padding-top: 100px;
    }
  }

  .communityBlogWelcomeSection {
    padding-top: 5em;
    position: relative;
    padding-bottom: 5em;

    .categorySelection {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    .categoryContent {
      .categoryContentGrid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 50px;
      }

      .categoryContentDesc {
        min-height: 78px;
      }
    }
  }

  .communityBlogWelcomeSection:before {
    content: "";
    position: absolute;
    top: -10em;
    left: 0;
    width: 100%;
    height: 10em;
    background-color: #bae6fd;
    transform: skewY(5deg);
    transform-origin: top left;
    z-index: -1;
  }

  .communityBlogShortsSection {
    padding-top: 10em;
    position: relative;

    .communityBlogShortGrid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
    }
  }

  .communityBlogShortsSection:before {
    content: "";
    position: absolute;
    top: 8em;
    left: 0;
    width: 100%;
    height: 10em;
    background-image: linear-gradient(to bottom, #fda4af, transparent);
    transform: skewY(355deg);
    transform-origin: top left;
    z-index: -1;
  }

  .beachSection {
    padding-top: 10em;
    .beachImg {
      // width: 100%;
      height: 600px;
      // object-fit: cover;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .beachSection:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10em;
    background-color: #fff;
    transform: skewY(5deg);
    transform-origin: top left;
  }
}

@media screen and (max-width: 768px) {
  .communityBlogPage {
    .communityBlogBlueSection {
      .sectionContainer {
        flex-direction: column;
        padding-top: 50px;
      }
    }

    .communityBlogWelcomeSection {
      padding-top: 5em;
      position: relative;
      padding-bottom: 5em;

      .categorySelection {
        flex-direction: column;
        align-items: flex-start;
      }

      .categoryContent {
        .categoryContentGrid {
          grid-template-columns: 1fr;
        }

        .categoryContentDesc {
          min-height: unset;
        }
      }
    }

    .communityBlogShortsSection {
      .communityBlogShortGrid {
        grid-template-columns: 1fr;
      }
    }

    .beachSection {
      padding-top: 10em;
      .beachImg {
        // width: 100%;
        height: 300px;
        // object-fit: cover;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}
