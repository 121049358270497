.bannerHome {
  margin-top: -90px;
  z-index: 1;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;

  width: 100%;
  height: calc(100vh - 175px);
  background-repeat: no-repeat;
  background-size: cover;

  .bannerImg {
    width: 100%;
    height: 100%;
    object-fit: cover;

    &.mobBannerSize {
      height: unset;
    }
  }

  .essentialBannerTextContainer {
    position: absolute;
    right: 200px;
    top: 250px;
    text-transform: uppercase;
    color: black;

    .essentialBannerText {
      font-size: 40px;
      margin-bottom: 20px;
    }
  }

  .leanEssentialBannerTextContainer {
    position: absolute;
    left: 0;
    top: 200px;
    text-transform: uppercase;
    color: white;
    max-width: 400px;

    .leanEssentialBannerText {
      font-size: 40px;
      margin-bottom: 20px;
    }
  }
}

.essentialBanner {
  background-position: calc(50% - 200px) calc(50%);
}

.leanBanner {
  background-position: calc(50% + 150px) calc(50%);
}

@media screen and (max-width: 768px) {
  .bannerHome {
    margin-top: -75px;
    height: calc(100vh - 30px);

    .essentialBannerTextContainer {
      top: 100px;
      left: 0;
      right: unset;

      .essentialBannerText {
        font-size: 18px;
        margin-bottom: 10px;
      }
    }

    .leanEssentialBannerTextContainer {
      left: 0;
      top: unset;
      bottom: 50px;
    }
  }

  .essentialBanner {
    background-position: calc(50% + 110px) calc(50%);
  }

  .leanBanner {
    background-position: calc(50% + 65px) calc(50%);
  }
}
