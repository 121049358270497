.contactPage {
  .bannerContainer {
    .banner {
      width: 100%;
    }

    .bannerText {
      top: 50%;
      transform: translateY(-50%);
      font-size: 40px;
      left: 15%;
      letter-spacing: 2px;
      color: var(--sub-txt-color);
    }
  }

  .contactForm {
    max-width: 450px;
    margin: 0 auto;
  }

  .locateUs {
    .gridContent {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      .gridItem {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .contactPage {
    .bannerContainer {
      width: 100%;
      height: 200px;

      .banner {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .bannerText {
        font-size: 20px;
        left: 15px;
        max-width: 250px;
        line-height: 1.5;
      }
    }

    .locateUs {
      .gridContent {
        grid-template-columns: unset;
        gap: 30px;

        .gridItem {
          display: flex;
          flex-direction: column;
          gap: 10px;
          align-items: center;
        }
      }
    }
  }
}
