.contentDividerContainer {
  .banner {
    width: 100%;
    margin-bottom: 30px;
  }

  .desc {
    margin-bottom: 100px;
  }
}
