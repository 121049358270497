// Cart Drawer
.cartDrawer {
  .cartContainer {
    height: 100%;

    &.emptyCartContainer {
      display: flex;

      .cartContent {
        margin: auto;
      }
    }

    &.hasCartContainer {
      .freeShippingContainer {
        border-bottom: 1px solid var(--border-grey);

        .progressBarContainer {
          position: relative;
        }
        .progressBarInactive {
          height: 10px;
          width: 100%;
          background-color: var(--border-grey);
          border-radius: 30px;
        }

        .progressBarActive {
          position: absolute;
          top: 0;
          height: 100%;
          background-color: var(--primary-bg-color);
          border-radius: 30px;
        }
      }

      .cartItemsContainer {
        .cartItem {
          border-bottom: 1px solid var(--border-grey);
          padding-bottom: 20px;

          .productDetails {
            .productImg {
              width: 80px;
            }

            .productDesc {
              .title {
                font-size: 18px;
              }
            }
          }

          .productCta {
            .itemQuantity {
              border: 1px solid var(--border-grey);
              font-size: 18px;
              .qtyDisplay {
                border: 0;
                text-align: center;
                width: 56px;
                height: 100%;
              }
              .qtyBtn {
                padding: 10px 20px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
              }
            }
          }

          .deleteBtn {
            padding: 10px;
            height: 44px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border: 1px solid var(--border-grey);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  // cart drawer
  .cartDrawer {
    .cartContainer {
      &.hasCartContainer {
        .cartItemsContainer {
          .cartItem {
            border-bottom: 1px solid var(--border-grey);
            padding-bottom: 20px;

            .productDetails {
              .productImg {
                width: 50px;
              }

              .productDesc {
                .title {
                  font-size: 14px;
                }

                .info {
                  font-size: 12px;
                }
              }
            }

            .productCta {
              .itemQuantity {
                font-size: 14px;
                .qtyDisplay {
                  width: 40px;
                }
                .qtyBtn {
                  padding: 10px;
                  font-size: 12px;
                }
              }
            }

            .deleteBtn {
              height: 38px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
