.announcementBar {
  background: var(--primary-bg-color);
  color: var(--sub-txt-color);

  :global(.paragraph) {
    line-height: normal;
  }
}

@media screen and (max-width: 768px) {
  .announcementBar {
    :global(.paragraph) {
      font-size: 12px;
    }
  }
}
