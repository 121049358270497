.condition-page {
  .body {
    img {
      width: 100%;
    }
  }
}

.condition-page,
.privacy-page,
.return-page {
  ol,
  ul {
    list-style-position: inside;
  }
}

@media screen and (max-width: 768px) {
  // Product Item page
  .product-page {
    .productBrief {
      display: block;
    }

    .ftImgWrapper {
      max-width: 70%;
      margin: 0 auto;

      img {
        width: 100%;
      }
    }

    .productFeature {
      .productFtImgSec {
        margin-bottom: 150px;

        .gridContent {
          grid-template-columns: 1fr;
          gap: 20px;
        }

        img {
          width: 100%;
        }
      }
    }
  }
}
