.footer {
  background-color: var(--secondary-bg-color);
  color: var(--sub-txt-color);
  padding: 50px 0;

  .logo {
    width: 200px;
  }

  .row {
    display: grid;
    grid-template-columns: 40fr 60fr;
    margin-bottom: 50px;
    gap: 150px;
  }

  .subscribeCol,
  .otherLinkCol {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .otherLinkCol {
    display: inline-flex;
  }

  .subscribeForm {
    border-bottom: 1px solid #fff;

    form {
      display: flex;
    }

    input {
      width: 70%;
      height: 50px;
      background: transparent;
      border: 1px solid var(--border-light-black);
      padding: 0 20px;

      &:focus-visible {
        outline: 1px solid var(--primary-bg-color);
      }
    }

    button {
      width: 30%;
      background: transparent;
      border: 0;
      font-size: 18px;
      cursor: pointer;
    }
  }

  .footerBottom :global(.paragraph) {
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) {
  .footer {
    .logo {
      width: 200px;
    }

    .row {
      grid-template-columns: unset;
      gap: 50px;
    }

    .footerBottom :global(.paragraph) {
      font-size: 10px;
    }
  }
}
