// test product
.test-product-page {
  .productBrief {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
    margin-bottom: 50px;

    .productImgsSwiper {
      width: 100%;
      padding-bottom: 30px;
      margin-bottom: 50px;

      .productSwiperImgWrapper {
        width: 100%;
        height: 100%;

        .displayImg {
          width: 100%;
          max-height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      .swiper-pagination {
        color: var(--primary-txt-color) !important;
        left: 50% !important;
        transform: translateX(-50%);
        border: 0;
        padding: 5px 10px;
        border-radius: 20px;
        font-size: 12px;
        bottom: 0;

        .swiper-pagination-bullet {
          width: 10px;
          height: 10px;
        }

        .swiper-pagination-bullet-active {
          background: var(--primary-bg-color);
          width: 50px;
          border-radius: 30px;
        }
      }
    }

    .productInfoContainer {
      .productInfoDesc {
        border-top: 1px solid var(--border-grey);
        border-bottom: 1px solid var(--border-grey);
        padding-top: 20px;
        padding-bottom: 20px;
        margin-bottom: 30px;
      }

      .productCustomize {
        .customoizeOptions {
          background-color: white;
          color: #000;
          font-weight: 700;
          border: 1px solid var(--primary-color);
          padding: 20px 15px;
        }
      }

      .productCta {
        display: grid;
        gap: 10px;
        grid-template-columns: 156px 1fr;

        .itemQuantity {
          border: 1px solid var(--border-grey);
          font-size: 18px;
          .qtyDisplay {
            border: 0;
            text-align: center;
            width: 56px;
            height: 100%;
          }
          .qtyBtn {
            padding: 10px 20px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
        }
      }

      .productShippingInfo {
        // background-color: var(--grey-bg-color);
        padding: 30px 15px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        border: 1px solid var(--border-grey);

        .countryPaymentContainer {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .countryInfo {
            display: flex;
            gap: 10px;
            align-items: center;

            img {
              width: 44px;
            }
          }
        }

        .freeDelivery {
          display: flex;
          gap: 10px;
        }

        .deliveryDays {
          display: flex;
          gap: 10px;
        }
      }
    }
  }

  .productBenefitsGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;

    .productBenefitsGridItem {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  .productFeature {
    .productFtImgSec {
      .gridContent {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 50px;
      }

      img {
        width: 100%;
      }
    }
  }

  .accordionContent {
    .accordionBox {
      border-top: 1px solid var(--border-grey);
      border-bottom: 1px solid var(--border-grey);

      .boxTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 32px;
        padding-bottom: 32px;
        cursor: pointer;

        .expandIcon {
          position: relative;
          width: 24px;
          height: 24px;

          &:before,
          &:after {
            content: "";
            position: absolute;
            background-color: var(--secondary-color);
            transition: transform 0.25s ease-out;
          }

          /* Vertical line */
          &:before {
            top: 0;
            left: 50%;
            width: 3px;
            height: 100%;
            margin-left: -2px;
          }

          /* horizontal line */
          &:after {
            top: 50%;
            left: 0;
            width: 100%;
            height: 3px;
            margin-top: -2px;
          }
        }
      }

      .boxTitle:hover {
        color: var(--primary-txt-color);

        .expandIcon {
          &:before {
            background-color: var(--primary-bg-color);
            // transform: rotate(90deg);
          }
          &:after {
            background-color: var(--primary-bg-color);
            // transform: rotate(180deg);
          }
        }
      }

      .boxContent {
        padding-bottom: 32px;

        .recommendAccContent {
          .accordionGridContent {
            display: grid;
            grid-template-columns: 25fr 75fr;
            gap: 20px;
          }
        }

        .supplementAccContent {
          .accordionGridContent {
            display: grid;
            grid-template-columns: 75fr 25fr;
            // row-gap: 20px;
          }
        }
      }
    }

    .accordionBox:first-child {
      border-bottom: 0;
    }

    .accordionBox:last-child {
      border-top: 0;
    }
  }

  .accordionBox.opened {
    .expandIcon {
      &:before {
        transform: rotate(90deg);
      }
      &:after {
        transform: rotate(180deg);
      }
    }
  }

  .addToCartFloatContainer {
    position: fixed;
    bottom: 50px;
    left: 30px;
    width: 100%;
    max-width: 1440px;

    .addToCartFloatButton {
      width: 350px;
      height: 40px;
      text-align: center;
    }
  }
}

@media screen and (max-width: 768px) {
  // test product
  .test-product-page {
    .productBrief {
      display: block;
    }

    .productBenefitsGrid {
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }

    .productCta {
      grid-template-columns: 1fr 1fr;
    }

    .productFeature {
      .productFtImgSec {
        .gridContent {
          grid-template-columns: 1fr;
          gap: 20px;
        }

        img {
          width: 100%;
        }
      }
    }

    .addToCartFloatContainer {
      left: 20px;

      .addToCartFloatButton {
        width: 150px;
      }
    }
  }
}
