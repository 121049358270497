.checkoutPage {
  .checkoutLayout {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }

  .cartInfo {
    .productItemImg {
      width: 64px;
      height: 64px;
      padding: 5px;
      background-color: #fff;
      border: 1px solid var(--border-grey);
      border-radius: 5px;
      display: flex;

      img {
        width: 100%;
        height: 100%;
        margin: auto;
        object-fit: contain;
      }

      .itemQty {
        position: absolute;
        top: -8px;
        right: -8px;
        background-color: #777;
        color: #fff;
        padding: 5px 8px;
        font-size: 12px;
        border-radius: 50%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  // Checkout
  .checkoutPage {
    .checkoutLayout {
      grid-template-columns: 1fr;
    }
  }
}
