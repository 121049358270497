.product-page {
  .productBrief {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
    margin-bottom: 50px;

    .productImagesContainer {
      display: flex;
      padding-bottom: 100px;
      max-height: 648px;

      .imgPreviewList {
        position: absolute;
        bottom: 0;
        // transform: translateY(-50%);
        left: 0;
        display: flex;
        gap: 20px;
      }

      .previewImgBox {
        width: 64px;
        height: 64px;
        padding: 10px;
        border: 1px solid var(--border-grey);
        margin-bottom: 20px;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .previewImgBox.active {
        border-bottom: 2px solid var(--border-red);
      }

      .displayImg {
        border: 1px solid var(--border-grey);
        width: 100%;
        display: flex;

        img {
          display: block;
          max-width: 80%;
          margin: auto;
          max-height: 100%;
        }
      }
    }

    .productImgsSwiper {
      margin-bottom: 50px;
      padding-bottom: 30px;

      .productSwiperImgWrapper {
        max-width: 70%;
        margin: 0 auto;

        .displayImg {
          width: 100%;
          height: 100%;
          max-height: 220px;
          object-fit: contain;
        }
      }

      .swiper-pagination {
        color: var(--primary-txt-color) !important;
        text-align: unset !important;
        width: unset !important;
        border: 1px solid var(--border-grey);
        padding: 5px 10px;
        border-radius: 20px;
        font-size: 12px;
        left: 25px;
      }

      .zoomIcon {
        position: absolute;
        right: 0;
        color: var(--primary-txt-color);
        border: 1px solid var(--border-grey);
        padding: 8px 5px;
        font-size: 12px;
        padding: 10px;
        bottom: 5px;
        cursor: pointer;
      }
    }

    .productInfoContainer {
      .productInfoDesc {
        border-top: 1px solid var(--border-grey);
        border-bottom: 1px solid var(--border-grey);
        padding-top: 20px;
        padding-bottom: 20px;
        margin-bottom: 30px;
      }

      .productCustomize {
        .customoizeOption {
          background-color: white;
          color: #000;
          font-weight: 700;
          border: 1px solid var(--primary-color);
          padding: 10px 24px;
        }
      }

      .productCta {
        .itemQuantity {
          border: 1px solid var(--border-grey);
          font-size: 18px;
          .qtyDisplay {
            border: 0;
            text-align: center;
            width: 56px;
            height: 100%;
          }
          .qtyBtn {
            padding: 10px 20px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
        }
      }

      .productShippingInfo {
        background-color: var(--grey-bg-color);
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .countryPaymentContainer {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .countryInfo {
            display: flex;
            gap: 10px;
            align-items: center;

            img {
              width: 44px;
            }
          }
        }

        .freeDelivery {
          display: flex;
          gap: 10px;
        }

        .deliveryDays {
          display: flex;
          gap: 10px;
        }
      }
    }
  }

  .productFeature {
    .productFtImgSec {
      margin-bottom: 150px;

      .gridContent {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 50px;
      }

      img {
        width: 100%;
      }
    }

    .accordionContent {
      .accordionBox {
        border-top: 1px solid var(--border-grey);
        border-bottom: 1px solid var(--border-grey);

        .boxTitle {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: 32px;
          padding-bottom: 32px;
          cursor: pointer;

          .expandIcon {
            position: relative;
            width: 24px;
            height: 24px;

            &:before,
            &:after {
              content: "";
              position: absolute;
              background-color: var(--secondary-color);
              transition: transform 0.25s ease-out;
            }

            /* Vertical line */
            &:before {
              top: 0;
              left: 50%;
              width: 3px;
              height: 100%;
              margin-left: -2px;
            }

            /* horizontal line */
            &:after {
              top: 50%;
              left: 0;
              width: 100%;
              height: 3px;
              margin-top: -2px;
            }
          }
        }

        .boxTitle:hover {
          color: var(--primary-txt-color);

          .expandIcon {
            &:before {
              background-color: var(--primary-bg-color);
              // transform: rotate(90deg);
            }
            &:after {
              background-color: var(--primary-bg-color);
              // transform: rotate(180deg);
            }
          }
        }

        .boxContent {
          padding-bottom: 32px;

          .recommendAccContent {
            .accordionGridContent {
              display: grid;
              grid-template-columns: 25fr 75fr;
              gap: 20px;
            }
          }

          .supplementAccContent {
            .accordionGridContent {
              display: grid;
              grid-template-columns: 75fr 25fr;
              // row-gap: 20px;
            }
          }
        }
      }

      .accordionBox:first-child {
        border-bottom: 0;
      }

      .accordionBox:last-child {
        border-top: 0;
      }
    }

    .accordionBox.opened {
      .expandIcon {
        &:before {
          transform: rotate(90deg);
        }
        &:after {
          transform: rotate(180deg);
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  // Product Item page
  .product-page {
    .productBrief {
      display: block;
    }

    .ftImgWrapper {
      max-width: 70%;
      margin: 0 auto;

      img {
        width: 100%;
      }
    }

    .productFeature {
      .productFtImgSec {
        margin-bottom: 150px;

        .gridContent {
          grid-template-columns: 1fr;
          gap: 20px;
        }

        img {
          width: 100%;
        }
      }
    }
  }
}
