.affPage {
  .affIntroSection {
    margin-top: 50px;
    margin-bottom: 100px;
    img {
      width: 100%;
    }
  }

  .affBenefitsInfo {
    padding-bottom: 10em;
  }

  .sloganSection {
    z-index: 2;
    .sloganBg {
      background-color: var(--primary-bg-color);
      padding-top: 5em;
    }

    .stylishTitle {
      letter-spacing: 20px;
    }
    .container {
      max-width: 800px;
    }
  }

  .sloganSection:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10em;
    background-color: var(--primary-bg-color);
    transform: skewY(355deg);
    transform-origin: top left;
    z-index: -1;
  }

  .btmImgSection {
    padding-top: 5em;
    background-color: var(--primary-bg-color);
    z-index: 1;
    .btmImg {
      // width: 100%;
      height: 600px;
      // object-fit: cover;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top;
    }
  }

  .btmImgSection:before {
    content: "";
    position: absolute;
    top: -5em;
    left: 0;
    width: 100%;
    height: 10em;
    background-color: var(--primary-bg-color);
    transform: skewY(5deg);
    transform-origin: top left;
  }
}

@media screen and (max-width: 768px) {
  .affPage {
    .affBenefitsInfo {
      padding-bottom: 0;
    }

    .btmImgSection {
      .btmImg {
        height: 300px;
      }
    }
  }
}
