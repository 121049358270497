.communityBlogItemPage {
  .blueSection {
    background-color: #bae6fd;
    padding-bottom: 5em;
    position: relative;

    .sectionContainer {
      display: flex;
      gap: 50px;
      align-items: flex-start;
      padding-top: 100px;
    }
  }

  .blueSection:before {
    content: "";
    position: absolute;
    bottom: -10em;
    left: 0;
    width: 100%;
    height: 10em;
    background-color: #bae6fd;
    transform: skewY(175deg);
    transform-origin: top left;
    z-index: -1;
  }

  .blogWritten {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .blogWrittenInfo {
      display: flex;
      align-items: center;
      gap: 30px;
    }

    .divider {
      display: block;
    }
  }

  .aboutAuthorTitle {
    text-align: left;
  }

  .aboutAuthorBody {
    display: flex;
    gap: 30px;
    align-items: flex-start;

    .aboutAuthorBodyDesc {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  .categorySection {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .beachSection {
    padding-top: 10em;
    .beachImg {
      // width: 100%;
      height: 600px;
      // object-fit: cover;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .beachSection:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10em;
    background-color: #fff;
    transform: skewY(5deg);
    transform-origin: top left;
  }
}

@media screen and (max-width: 768px) {
  .communityBlogItemPage {
    .blueSection {
      background-color: #bae6fd;
      padding-bottom: 5em;
      position: relative;

      .sectionContainer {
        display: flex;
        gap: 50px;
        align-items: flex-start;
        padding-top: 100px;
      }
    }

    .blueSection:before {
      content: "";
      position: absolute;
      bottom: -10em;
      left: 0;
      width: 100%;
      height: 10em;
      background-color: #bae6fd;
      transform: skewY(175deg);
      transform-origin: top left;
      z-index: -1;
    }

    .blogWritten {
      flex-direction: column;
      justify-content: unset;
      align-items: flex-start;
      gap: 10px;

      .blogWrittenInfo {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
      }

      .divider {
        display: none;
      }
    }

    .aboutAuthorTitle {
      text-align: center;
    }

    .aboutAuthorBody {
      flex-direction: column;
      align-items: center;

      .aboutAuthorBodyDesc {
        align-items: center;
      }
    }

    .categorySection {
      flex-direction: column;
      justify-content: unset;
      align-items: flex-start;
      gap: 10px;
    }

    .beachSection {
      padding-top: 10em;
      .beachImg {
        // width: 100%;
        height: 300px;
        // object-fit: cover;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}
