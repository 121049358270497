// Zoom Img Drawer
.ant-drawer {
  .zoomImgDrawer {
    .ant-drawer-header {
      border-bottom: 0 !important;
    }

    .ant-drawer-close {
      border: 1px solid var(--border-grey);
      padding: 10px;
      color: var(--primary-color);
      width: 42px;
      min-height: 42px;
      border-radius: unset !important;
      top: 20px;
    }

    .zoomImgContainer {
      height: 100%;

      .zoomImgsSwiper {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .swiper-wrapper {
          height: unset;
        }

        .swiper-button-prev,
        .swiper-button-next {
          background-color: var(--sub-bg-color);
          top: unset;
          transform: unset;
          bottom: 0;
          border: 1px solid var(--border-grey);
        }

        .swiper-button-prev:after,
        .swiper-button-next:after {
          color: var(--primary-txt-color) !important;
        }

        .zoomSwiperImgWrapper {
          .displayImg {
            width: 100%;
            height: 100%;
            max-height: 500px;
            object-fit: contain;
          }
        }
      }
    }
  }
}
