// Menu Drawer
.ant-drawer {
  .menuDrawer {
    .ant-drawer-body {
      padding: 0;
    }
    .ant-menu {
      border-inline-end: 0 !important;
      font-weight: bold !important;
      text-transform: uppercase;
    }

    .ant-menu.ant-menu-sub.ant-menu-inline {
      background: #fff !important;
    }

    .ant-menu-inline.ant-menu-root .ant-menu-submenu-title,
    .ant-menu-item.ant-menu-item-only-child {
      // padding-left: 0 !important;
      padding-right: 0 !important;
      margin: 0 !important;
      margin-bottom: 20px !important;
      outline: 0 !important;
      background: #fff !important;
      transition: unset !important;

      &:hover {
        background-color: #fff !important;
      }

      &.active {
        background-color: unset !important;
      }
    }

    .ant-menu-submenu-arrow {
      right: 15px !important;
    }
  }

  .menuContainer {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  // Product Item page
  .product-page {
    .productBrief {
      display: block;
    }

    .ftImgWrapper {
      max-width: 70%;
      margin: 0 auto;

      img {
        width: 100%;
      }
    }

    .productFeature {
      .productFtImgSec {
        margin-bottom: 150px;

        .gridContent {
          grid-template-columns: 1fr;
          gap: 20px;
        }

        img {
          width: 100%;
        }
      }
    }
  }
}
