// Address List
.addressList {
  .addressListContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;

    .gridItem {
      border: 1px solid var(--border-grey);
      padding: 15px;
    }
  }
}

@media screen and (max-width: 768px) {
  // Address
  .addressList {
    .addressListContainer {
      grid-template-columns: 1fr;
    }
  }
}
