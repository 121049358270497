// Product List
.productListPage {
  .productListContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }

  .productImgContainer {
    width: 100%;
    display: flex;
    img {
      width: 80%;
      height: 350px;
      object-fit: contain;
      margin: auto;
    }

    .addToCartBtn {
      width: 50px;
      height: 50px;
      border: 1px solid var(--border-grey);
      color: var(--primary-txt-color);
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 0;
      right: 0;
      cursor: pointer;

      &:hover {
        border: 1px solid var(--primary-color);
      }
    }
  }

  .productName {
    .big-title {
      font-size: 25px !important;
    }
  }
}

@media screen and (max-width: 1020px) {
  .productListPage {
    .productListContainer {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media screen and (max-width: 768px) {
  // Product page
  .productListPage {
    .productListContainer {
      grid-template-columns: 1fr;
    }
  }
}
