// Account page
.account-page {
  .titleContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 50px 0;
  }

  .welcomeContent {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  .tabItem {
    font-size: 25px;
    font-weight: bold;
    text-transform: uppercase;
  }
}

@media screen and (max-width: 768px) {
  // Account page
  .account-page {
    .titleContent {
      display: flex;
      flex-direction: column;
      justify-content: unset;
      gap: 15px;
      margin: 50px 0 30px 0;
    }

    .tabItem {
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}
